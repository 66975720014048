<template>
	<div class="inner">
		<p style="background-color: #ffffff; margin: 0pt">
			<a style="color: #172b4d" href="javascript:;"
				><span style="color: #172b4d; font-family: 宋体; font-size: 21pt; text-decoration: underline">《</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 21pt; text-decoration: underline">线下实训资源提供机构承诺书</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 21pt; text-decoration: underline">》</span></a
			>
		</p>
		<p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0"><span style="font-family: 等线; font-size: 10.5pt">&#xa0;</span></p>
		<p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
			<span style="font-family: 等线; font-size: 10.5pt"
				>请认真阅读本《线下实训资源提供机构承诺书》（以下简称“承诺书”），确保您充分理解本协议中各条款。除非您接受本协议所有条款，否则您无权申请入驻、注册、登录或使用本协议所涉服务。您的申请入驻、注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</span
			>
		</p>
		<p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0"><span style="font-family: 等线; font-size: 10.5pt">&#xa0;</span></p>
		<p style="margin: 0pt 0pt 0pt 21pt; orphans: 0; text-align: justify; text-indent: -21pt; widows: 0">
			<span style="font-family: 等线; font-size: 10.5pt; font-weight: bold">一、</span
			><span style="font-family: 等线; font-size: 10.5pt; font-weight: bold">基本要求</span>
		</p>
		<ol type="1" style="margin: 0pt; padding-left: 0pt">
			<li
				style="
					font-family: 等线;
					font-size: 10.5pt;
					margin: 0pt 0pt 0pt 12.83pt;
					orphans: 0;
					padding-left: 8.17pt;
					text-align: justify;
					text-indent: 0pt;
					widows: 0;
				"
			>
				<span style="font-family: 等线; font-size: 10.5pt">应遵守国家相关法律法规、职业教育方针和就业政策。</span>
			</li>
			<li
				style="
					font-family: 等线;
					font-size: 10.5pt;
					margin: 0pt 0pt 0pt 12.83pt;
					orphans: 0;
					padding-left: 8.17pt;
					text-align: justify;
					text-indent: 0pt;
					widows: 0;
				"
			>
				<span style="font-family: 等线; font-size: 10.5pt">应具有独立法人资格或经法人主体授权。</span>
			</li>
			<li
				style="
					font-family: 等线;
					font-size: 10.5pt;
					margin: 0pt 0pt 0pt 12.83pt;
					orphans: 0;
					padding-left: 8.17pt;
					text-align: justify;
					text-indent: 0pt;
					widows: 0;
				"
			>
				<span style="font-family: 等线; font-size: 10.5pt">应具有能够承担相应培训任务的师资力量、设施设备及场地资源。</span>
			</li>
			<li
				style="
					font-family: 等线;
					font-size: 10.5pt;
					margin: 0pt 0pt 0pt 12.83pt;
					orphans: 0;
					padding-left: 8.17pt;
					text-align: justify;
					text-indent: 0pt;
					widows: 0;
				"
			>
				<span style="font-family: 等线; font-size: 10.5pt">应具备基本的组织决策机构、管理制度、财务制度、责任制度等制度体系。</span>
			</li>
			<li
				style="
					font-family: 等线;
					font-size: 10.5pt;
					margin: 0pt 0pt 0pt 12.83pt;
					orphans: 0;
					padding-left: 8.17pt;
					text-align: justify;
					text-indent: 0pt;
					widows: 0;
				"
			>
				<span style="font-family: 等线; font-size: 10.5pt"
					>应具备健全的教学管理制度，包括培训服务管理、培训对象管理、培训师资管理、培训档案管理、后勤服务、应急预案等各项制度。</span
				>
			</li>
		</ol>
		<p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0"><span style="font-family: 等线; font-size: 10.5pt">&#xa0;</span></p>
		<p style="margin: 0pt 0pt 0pt 21pt; orphans: 0; text-align: justify; text-indent: -21pt; widows: 0">
			<span style="font-family: 等线; font-size: 10.5pt; font-weight: bold">二、</span
			><span style="font-family: 等线; font-size: 10.5pt; font-weight: bold">设施设备</span>
		</p>
		<p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
			<span style="font-family: 等线; font-size: 10.5pt">请参考</span
			><span style="font-family: 等线; font-size: 10.5pt; font-weight: bold">各职业培训规范</span
			><span style="font-family: 等线; font-size: 10.5pt; font-weight: bold">团体</span
			><span style="font-family: 等线; font-size: 10.5pt; font-weight: bold">标准</span
			><span style="font-family: 等线; font-size: 10.5pt">对于设备的要求</span><span style="font-family: 等线; font-size: 10.5pt">，</span
			><span style="font-family: 等线; font-size: 10.5pt">请确保</span
			><span style="font-family: 等线; font-size: 10.5pt">满足标准中提到的设备要求。</span>
		</p>
		<p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0"><span style="font-family: 等线; font-size: 10.5pt">&#xa0;</span></p>
		<p style="margin: 0pt 0pt 0pt 21pt; orphans: 0; text-align: justify; text-indent: -21pt; widows: 0">
			<span style="font-family: 等线; font-size: 10.5pt; font-weight: bold">三、</span
			><span style="font-family: 等线; font-size: 10.5pt; font-weight: bold">法律责任</span>
		</p>
		<p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
			<span style="font-family: 等线; font-size: 10.5pt">1、如果发现或收到他人举报或投诉用户违反本协议约定的，有权不经通知随时</span
			><span style="font-family: 等线; font-size: 10.5pt">与其解除合作关系，同时视违反者违反行为程度，有权对其追究法律责任</span
			><span style="font-family: 等线; font-size: 10.5pt">。</span>
		</p>
		<p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
			<span style="font-family: 等线; font-size: 10.5pt">2、用户理解并同意，有权</span><span style="font-family: 等线; font-size: 10.5pt">依合理</span
			><span style="font-family: 等线; font-size: 10.5pt"
				>判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿</span
			><span style="font-family: 等线; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
			><span style="font-family: 等线; font-size: 10.5pt">及各关联公司与合作公司、关联公司，并使之免受损害。</span>
		</p>
	</div>
</template>

<script>
export default {
	name: 'offlineTrainingProtocol',
	data() {
		return {
			show: false,
		}
	},
	props: {
		dialogShow1: Boolean,
	},
	watch: {
		dialogShow1(val) {
			this.show = val
		},
	},
	methods: {
		handleClose() {
			this.$emit('close')
		},
	},
}
</script>

<style lang="stylus" scoped></style>
