<template>
	<div class="institutionsIn">
		<van-nav-bar class="nav_bar" :title="!$store.state.pages.showAddTeachState ? '参培机构入驻申请' : '班主任信息'" placeholder>
			<template #left>
				<van-icon name="arrow-left" size="20" @click="onClickLeft" />
				<van-icon name="wap-home-o" size="20" @click="goHome" />
			</template>
		</van-nav-bar>
		<div v-show="!$store.state.pages.showAddTeachState">
			<van-form ref="validateForm" validate-first input-align="right" :show-error="false" :show-error-message="false">
				<!-- <van-field required label="入驻类型" placeholder="请选择入驻类型" :rules="[{ message: '请选择入驻类型' }]" class="radio-box">
					<template #input>
						<van-radio-group v-model="formData.enterType" @change="handleEnterTypeChange" direction="horizontal">
							<el-radio :label="2">参训机构</el-radio>
							<el-radio :label="1">参训机构（类型2）</el-radio>
							<el-radio :label="7">参训机构（类型3）</el-radio>
						</van-radio-group>
					</template>
				</van-field> -->
				<van-field
					required
					rows="1"
					autosize
					type="textarea"
					v-model.trim="formData.name"
					name="用户名"
					label="机构名称"
					maxlength="40"
					placeholder="请输入机构名称"
				/>

				<van-field class="zhihui" required v-model.trim="formData.userName" label="联系人" maxlength="30" placeholder="请输入联系人姓名" />
				<van-field class="zhihui" required type="number" v-model.trim="formData.phone" label="联系电话" maxlength="11" placeholder="请输入联系电话" />
				<van-field class="zhihui" required v-model.trim="formData.authCode" label="验证码" placeholder="请输入验证码">
					<template #input>
						<input type="text" class="auth-input" v-model.trim="formData.authCode" />
						<div class="auth-code" @click="getAuthCode">
							{{ Countdown > 0 ? `${Countdown}s` : '获取验证码' }}
						</div>
					</template>
				</van-field>
				<van-field
					class="zhihui"
					required
					type="text"
					maxlength="40"
					v-model.trim="formData.email"
					label="邮箱"
					placeholder="请输入邮箱"
				/>
				<van-field
					class="zhihui"
					required
					v-model.trim="formData.emailAuthCode"
					label="验证码"
					placeholder="请输入验证码"
				>
					<template #input>
					<input
						type="text"
						class="auth-input"
						v-model.trim="formData.emailAuthCode"
					/>
					<div class="auth-code" @click="getAuthCodeEmail">
						{{ CountdownEmail > 0 ? `${CountdownEmail}s` : "获取验证码" }}
					</div>
					</template>
				</van-field>
				<van-field readonly required clickable name="area" :value="value" label="地址" placeholder="点击选择省市区" @click="showArea = true" />
				<van-popup v-model="showArea" position="bottom">
					<van-area :area-list="cityList" @confirm="onConfirm" @cancel="showArea = false" />
				</van-popup>
				<van-field class="zhihui" required v-model.trim="formData.addressDetail" label="详细地址" placeholder="请输入详细地址" maxlength="50" />
				<van-field
					class="zhihui"
					required
					v-model.trim="formData.organCode"
					label="统一社会信用代码"
					maxlength="18"
					placeholder="请输入统一社会信用代码"
				/>
				<div class="license-box upload-photo-box">
					<van-field required name="uploader" label="营业执照副本">
						<template #input>
							<div class="photo_box">
								<div class="my_photo" v-if="formData.businessLicenseImage">
									<!-- <img :src="userInfo.idPhoto" /> -->
									<img src="../assets/images/delete-icon.png" alt="" class="del-icon" @click="delLicenseImg" />
									<div class="tips">
										<img :src="formData.businessLicenseImage" class="re_uploadImg" />
									</div>
								</div>
								<div class="file_box" v-else>
									<div class="upload-icon">
										<img src="../assets/images/upload-icon.png" alt="" />
										<span>上传图片</span>
									</div>
									<input type="file" id="photoFile" class="file" ref="file" @change="readFile($event, 1)"/>
								</div>
							</div>
						</template>
					</van-field>
				</div>

				<!-- 公共实训基地不展示 -->
				<div class="switch-box" v-if="formData.enterType != 7">
					<van-field required name="switch" label="是否允许加入人才库" label-width="288px">
						<template #input>
							<van-switch v-model="formData.isJoinTalent" size="20" active-color="#32D74B" />
						</template>
					</van-field>
				</div>
				<p class="switch-title" v-if="formData.enterType != 7">
					<span>
						是否允许机构下的学员加入人才库，加入后平台可依据用户学习结果、测评结果等行为分析，利用大数据手段为招聘企业、应聘学员进行适岗匹配推荐
					</span>
				</p>

				<SelectTree
					v-show="formData.enterType == 2 || formData.enterType == 7"
					:showPicker="showPicker"
					@workerCode="workerCode"
					@workerName="getWorkerName"
				/>
				<div class="worker-box" v-show="workerName.length && (formData.enterType == 2 || formData.enterType == 7)">
					<div class="inner-worker">
						<p class="work-item" v-for="(item, index) in workerName" :key="index">
							{{ item }}
						</p>
					</div>
				</div>
				<div class="upload-photo-box" v-show="formData.enterType == 2 || formData.enterType == 7">
					<van-field required name="uploader" label="培训教师资格证明">
						<template #input>
							<div class="photo_box">
								<div class="my_photo" v-for="(item, index) in formData.trainTeacherImage" :key="index">
									<!-- <img :src="userInfo.idPhoto" /> -->
									<img src="../assets/images/delete-icon.png" alt="" class="del-icon" @click="delTeachImg(index)" />
									<div class="tips">
										<img :src="item" class="re_uploadImg" />
									</div>
								</div>
								<div class="file_box">
									<div class="upload-icon">
										<img src="../assets/images/upload-icon.png" alt="" />
										<span>上传图片</span>
									</div>
									<input type="file" id="photoFile" class="file" ref="file" @change="readFile($event, 2)" accept="image/*" />
								</div>
							</div>
						</template>
					</van-field>
				</div>
				<div class="upload-photo-box-zip" v-show="formData.enterType == 2 || formData.enterType == 7">
					<van-field required name="uploader" label="师资备案">
						<template #input>
							<div class="auth-code">
								<upload-zip  
									ref="uploadFileInput"
									:maxSize="1024*1024*50"
									@file-obj="getFileObj"
									@on-upload-cancel-file="onUploadCanceFile"
									@openInput="openInput()"
									:disabledUpload="(formData.urlList.length || fileListZip&&fileListZip.length || fileListFinished&&fileListFinished.length)?true:false"
									@fileloadingdata="fileloadingdata">
								</upload-zip>
							</div>
						</template>
					</van-field>
					<div class="teacher-info-box" v-if="formData.urlList && formData.urlList.length">
						<div class="teach-item" v-for="(item, index) in formData.urlList" :key="index">
							<span class="teach-name">{{ item.name }}</span>
							<span class="option-box">
								<i class="del" @click="delTeachZip(index)">
									<img src="../assets/images/delete.png" alt="" />
								</i>
							</span>
						</div>
					</div>
					<div class="teacher-info-box" v-if="fileListZip && fileListZip.length">
						<div class="teach-item" v-for="(item, index) in fileListZip" :key="index">
							<span class="teach-name">{{ item.name }}</span>
							<span class="option-box-zip">
								正在上传
							</span>
						</div>
					</div>
				</div>
				<van-field class="zhihui" required label="班主任信息">
					<template #input>
						<div class="auth-code" @click="addTeacherInfo">
							{{ '+ 添加' }}
						</div>
					</template>
				</van-field>
				<div class="teacher-info-box" v-if="formData.headTeacherVoList && formData.headTeacherVoList.length">
					<div class="teach-item" v-for="(item, index) in formData.headTeacherVoList" :key="index">
						<span class="teach-name">{{ item.name }}</span>
						<span class="option-box">
							<i class="edit" @click="editTeachInfo(item)">编辑</i>
							<i class="del" @click="delTeachInfo(index)">
								<img src="../assets/images/delete.png" alt="" />
							</i>
						</span>
					</div>
				</div>
				<div class="upload-photo-box mix-zip" v-show="formData.enterType == 2 || formData.enterType == 7">
					<van-field required name="uploader" label="场地设备信息">
						<template #input>
							<div class="photo_box">
								<div class="my_photo" v-for="(item, index) in formData.deviceList" :key="index">
									<!-- <img :src="userInfo.idPhoto" /> -->
									<img src="../assets/images/delete-icon.png" alt="" class="del-icon" @click="delDeviceImg(index)" />
									<div class="tips">
										<img :src="item.url" class="re_uploadImg" />
									</div>
								</div>
								<div class="file_box">
									<div class="upload-icon">
										<img src="../assets/images/upload-icon.png" alt="" />
										<span>上传图片</span>
									</div>
									<input type="file" id="photoFile" class="file" ref="file" @change="readFileImg($event, 10,'deviceList')" accept="image/*" />
								</div>
							</div>
						</template>
					</van-field>
					<van-field name="uploader" label="" class="mix-btn-upload">
						<template #input>
							<div class="auth-code-mix">
								<upload-zip-mix  
									:uploadType="'deviceRarList'"
									:clearType="'deviceRarList'"
									ref="deviceRarList"
									:maxSize="1024*1024*50"
									@file-obj="getFileObjMix"
									:uploadFileStatus="previewList.deviceRarList.uploadFileStatus"
									:disabledUpload="(formData.deviceRarList.length + formData.deviceList.length)>9"
									@on-upload-cancel-file="onUploadCanceFileMix"
									:hasUpload="formData.deviceRarList.length + formData.deviceList.length"
									@fileloadingdata="fileloadingdataMix">
								</upload-zip-mix>
							</div>
						</template>
					</van-field>
					<div class="teacher-info-box" v-if="formData.deviceRarList && formData.deviceRarList.length">
						<div class="teach-item" v-for="(item, index) in formData.deviceRarList" :key="index">
							<span class="teach-name">{{ item.name }}</span>
							<span class="option-box">
								<i class="del" @click="delZipMix('deviceRarList',index)">
									<img src="../assets/images/delete.png" alt="" />
								</i>
							</span>
						</div>
					</div>
					<div class="teacher-info-box" v-if="previewList.deviceRarList.fileListZip && previewList.deviceRarList.fileListZip.length">
						<div class="teach-item" v-for="(item, index) in previewList.deviceRarList.fileListZip" :key="index">
							<span class="teach-name">{{ item.name }}</span>
							<span class="option-box-zip">
								正在上传
							</span>
						</div>
					</div>
				</div>
				<div class="license-box-letter upload-photo-box">
					<van-field required name="uploader" label="承诺函">
						<template #input>
							<div class="photo_box">
							<div class="my_photo" v-if="formData.letterList.length>0">
								<!-- <img :src="userInfo.idPhoto" /> -->
								<img src="../assets/images/delete-icon.png" alt="" class="del-icon" @click="delLetterImg" />
								<div class="tips">
								<img :src="formData.letterList[0].url" class="re_uploadImg" />
								</div>
							</div>
							<div class="file_box" v-else>
								<div class="upload-icon">
								<img src="../assets/images/upload-icon.png" alt="" />
								<span>上传图片</span>
								</div>
								<input type="file" id="photoFile" class="file" ref="file" @change="readFileImg($event, 1,'letterList')" accept="image/*" />
							</div>
							</div>
						</template>
					</van-field>
					<div class="demoLetter" label="承诺函">
						<p>承诺函示例</p>
						<div class="right-box">
						<img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/knowDemo.png" alt="" @click="viewDemo">
						<!-- <span @click="download">下载承诺函模板</span> -->
						</div>
					</div>
				</div>
				<div class="upload-photo-box mix-zip" v-show="formData.enterType == 2 || formData.enterType == 7">
					<van-field  name="uploader" label="其他辅助证明资料">
						<template #input>
							<div class="photo_box">
								<div class="my_photo" v-for="(item, index) in formData.materialList" :key="index">
									<!-- <img :src="userInfo.idPhoto" /> -->
									<img src="../assets/images/delete-icon.png" alt="" class="del-icon" @click="delMaterImg(index)" />
									<div class="tips">
										<img :src="item.url" class="re_uploadImg" />
									</div>
								</div>
								<div class="file_box">
									<div class="upload-icon">
										<img src="../assets/images/upload-icon.png" alt="" />
										<span>上传图片</span>
									</div>
									<input type="file" id="photoFile" class="file" ref="file" @change="readFileImg($event,10,'materialList')" accept="image/*" />
								</div>
							</div>
						</template>
					</van-field>
					<van-field name="uploader" label="">
						<template #input>
							<div class="auth-code-mix">
								<upload-zip-mix  
									:uploadType="'materialRarList'"
									:clearType="'materialRarList'"
									ref="materialRarList"
									:maxSize="1024*1024*50"
									@file-obj="getFileObjMix"
									:uploadFileStatus="previewList.materialRarList.uploadFileStatus"
									@on-upload-cancel-file="onUploadCanceFileMix"
									:disabledUpload="(formData.materialRarList.length + formData.materialRarList.length)>9"
									:hasUpload="formData.materialRarList.length + formData.materialList.length"
									@fileloadingdata="fileloadingdataMix">
								</upload-zip-mix>
							</div>
						</template>
					</van-field>
					<div class="teacher-info-box" v-if="formData.materialRarList && formData.materialRarList.length">
						<div class="teach-item" v-for="(item, index) in formData.materialRarList" :key="index">
							<span class="teach-name">{{ item.name }}</span>
							<span class="option-box">
								<i class="del" @click="delZipMix('materialRarList',index)">
									<img src="../assets/images/delete.png" alt="" />
								</i>
							</span>
						</div>
					</div>
					<div class="teacher-info-box" v-if="previewList.materialRarList.fileListZip && previewList.materialRarList.fileListZip.length">
						<div class="teach-item" v-for="(item, index) in previewList.materialRarList.fileListZip" :key="index">
							<span class="teach-name">{{ item.name }}</span>
							<span class="option-box-zip">
								正在上传
							</span>
						</div>
					</div>
				</div>
			</van-form>
			<div class="handle_btn">
				<div class="commitment-box">
					<van-checkbox v-model="checked" />
					<p>
						我同意并遵守<span @click="checkInstitutions(1)">《机构入驻合作协议》</span
						><span @click="checkInstitutions(2)">《线下实训资源提供机构承诺书》</span>
						并保证符合承诺书内容
					</p>
				</div>
				<div class="apply-btn" ref="buttonRef" @click="apply">申请</div>
			</div>
		</div>
		<AddTeacherInfo v-if="$store.state.pages.showAddTeachState" @teachInfo="getTeachInfo" />
		<van-popup v-model="show1" round position="bottom" :style="{ height: '75%' }">
			<div class="popup_body">
				<div class="header">
					<span class="protocol-title">《机构入驻合作协议》</span>
					<span @click="show1 = false">
						<svg-icon icon-class="close" class-name="close"></svg-icon>
					</span>
					<div class="line"></div>
				</div>
				<div class="line"></div>
				<div class="content">
					<InstitutionInProtocol />
				</div>
				<div class="footer">
					<div class="line"></div>
					<div class="commitment-box">
						<van-checkbox v-model="checked" />
						<p>我同意并遵守<span>《机构入驻合作协议》</span><span>《线下实训资源提供机构承诺书》</span> 并保证符合承诺书内容</p>
					</div>
					<div class="apply-btn" @click="show1 = false">确认</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="show2" round position="bottom" :style="{ height: '75%' }">
			<div class="popup_body">
				<div class="header">
					<span class="protocol-title">《线下实训资源提供机构承诺书》</span>
					<span @click="show2 = false">
						<svg-icon icon-class="close" class-name="close"></svg-icon>
					</span>
					<div class="line"></div>
				</div>
				<div class="line"></div>
				<div class="content">
					<OfflineTrainingProtocol />
				</div>
				<div class="footer footer2">
					<div class="line"></div>
					<div class="commitment-box">
						<van-checkbox v-model="checked" />
						<p>我同意并遵守<span>《机构入驻合作协议》</span><span>《线下实训资源提供机构承诺书》</span> 并保证符合承诺书内容</p>
					</div>
					<div class="apply-btn" @click="show2 = false">确认</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import InstitutionInProtocol from './institutionInProtocol'
import OfflineTrainingProtocol from './offlineTrainingProtocol'
import UploadZip from '../component/upload-zip'
import UploadZipMix from '../component/upload-zip-mix'

import { checkForm } from '../component/checkform'
import { ImagePreview } from 'vant';
import { exportMethod } from "@/utils/download";
import URL from "@/config/url";


export default {
	name: 'institutionsIn',
	components: {
		SelectTree: () => import('../component/SelectTree'),
		AddTeacherInfo: () => import('../component/AddTeacherInfo'),
		InstitutionInProtocol,
		OfflineTrainingProtocol,
		UploadZip,
		UploadZipMix
	},
	mounted() {
		this.getRegionList()
	},
	data() {
		return {
			isShowInstitutionInProtocol: false, // 协议显示
			isShowOfflineTrainingProtocol: false, // 协议显示
			Protocol: InstitutionInProtocol,
			protocolTitle: '《线下实训资源提供机构承诺书》',
			formData: {
				name: '',
				addressDetail: '',
				phone: '',
				province: '',
				city: '',
				district: '',
				regionCode: '',
				organCode: '', // 社会信用代码
				authCode: '', // 验证码
				emailAuthCode:'',
				email:'',
				trainTeacherImage: [],
				businessLicenseImage: '',
				deviceList:[],
				urlList:[], 
				deviceRarList:[],
				materialRarList:[],
				letterList:[],
				materialList:[],
				isJoinTalent: true, // 0 否  1是
				workType: '', //工种
				enterType: 2, // 1分销渠道方 2分销商+实训基地 3教培机构 4企业 5政府及协会 6院校 7公共线下实训基地
				headTeacherVoList: [], // 添加班主任信息列表
			},
			fileListFinished:[],
			fileListZip:[],
			switchChecked: 1,
			value: '',
			showArea: false,
			cityList: {
				province_list: {},
				city_list: {},
				county_list: {},
			}, // 数据格式见 Area 组件文档
			showPicker: false,
			checked: false, // 是否同意承诺书
			imgUrlList: [], // 上传图片数组
			teachImgUrlList: [], //培训教师资格证图片
			Countdown: 0, // 获取验证码倒计时
			CountdownEmail:0,
			activeId: 0,
			workerName: [], // 已选择的工种内容
			isPhone: /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/,
			isEmail:/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
			reg: /^[A-Z0-9]{18}$/, // 信用代码验证
			show1: false,
			show2: false,
			previewList:{
				deviceRarList:{
					fileListZip:[],
					fileListFinished:[],
					uploadFileStatus:false,
				},
				materialRarList:{
					fileListZip:[],
					fileListFinished:[],
					uploadFileStatus:false,
				}
			}
		}
	},
	destroyed() {
		this.$store.commit('getTeachItemInfo', {
			id: this.activeId++,
			certificateImgs: [],
		})
		this.$store.commit('getAddTeachState', false)
	},
	watch: {
		formData: {
			handler(newVal) {
				let { success } = checkForm(newVal)
				if (this.checked && success) {
					this.$refs.buttonRef.style.backgroundColor = '#316FFF'
				} else {
					this.$refs.buttonRef.style.backgroundColor = '#CBCED5'
				}
			},
			deep: true,
		},
		checked(newVal) {
			let { success } = checkForm(this.formData)
			if (newVal && success) {
				this.$refs.buttonRef.style.backgroundColor = '#316FFF'
			} else {
				this.$refs.buttonRef.style.backgroundColor = '#CBCED5'
			}
			console.log('---->formdata', this.formData)
		},
		'formData.headTeacherVoList.length'() {
			if (this.formData.enterType == 2 || this.formData.enterType == 7) {
				this.formData.headTeacherVoList.map((item, indexs) => {
					this.formData.headTeacherVoList.map((ite, index) => {
						if (ite.id == item.id && indexs != index) {
							this.formData.headTeacherVoList.splice(index, 1)
						}
					})
				})
			}
		},
	},
	methods: {
		fileloadingdataMix(params) {
			const data = params.data;
			const type = params.type;
            console.log("data", data);
            // 找到已经在上传的文件索引，有就替换，没有就新增
            const sameIndex = this.previewList[type].fileListZip.findIndex(
            item => item.index === data.index
            );
            if (sameIndex !== -1) {
            if (data.isDelete) {
                this.previewList[type].fileListZip.splice(sameIndex, 1);
            } else {
                this.previewList[type].fileListZip.splice(sameIndex, 1, data);
            }
            } else {
            this.previewList[type].fileListZip.push(data);
            }
    
            this.previewList[type].fileListZip.forEach(item => {
				if (item.index === data.index) {
					this.$set(item, "percent", data.percent);
				}
				const status = item.percent < 100;
				this.$set(item, "uploadStatus", status);
            });
            const isAllFinished = this.previewList[type].fileListZip.every(item => item.percent === 100);
            if (isAllFinished) {
				this.afterUploadMix(type);
				this.previewList[type].uploadFileFlag = false;
				this.previewList[type].uploadFileStatus = false;
			} else {
				this.previewList[type].uploadFileStatus = true;
				this.previewList[type].uploadFileFlag = true;
            }
		},
		afterUploadMix(type){
			this.previewList[type].fileListFinished = this.previewList[type].fileListFinished.concat(this.previewList[type].fileListZip);
			this.previewList[type].fileListZip = [];
		},
		getFileObjMix(params) {
            this.formData[params.type].push({...params.data});
        },
		onUploadCanceFileMix(params){
            if (this.previewList[params.type].fileListZip.length > 0) {
                this.previewList[params.type].uploadFileStatus = true;
            } else {
                this.previewList[params.type].uploadFileStatus = false;
            }
        },
		openInput() {
			if(this.fileListFinished.length>0 || this.fileListZip.length>0){
				return
			}
			if(!this.uploadFileStatus){
				if(this.formData.urlList.length > 1){
					this.$toast.fail('文件个数不能超过1个');
				}else{
					this.$refs.uploadFileInput.handleClick();
				}
			}else{
				this.$toast.fail('文件正在上传中，请稍后操作');
			}
		},
		getFileObj(data) {
            this.formData.urlList.push({...data});
		},
		onUploadCanceFile(){
            if (this.fileListZip.length > 0) {
                this.uploadFileStatus = true;
            } else {
                this.uploadFileStatus = false;
            }
		},
		fileloadingdata(data) {
            console.log("data", data);
            // 找到已经在上传的文件索引，有就替换，没有就新增
            const sameIndex = this.fileListZip.findIndex(
            item => item.index === data.index
            );
            if (sameIndex !== -1) {
            if (data.isDelete) {
                this.fileListZip.splice(sameIndex, 1);
            } else {
                this.fileListZip.splice(sameIndex, 1, data);
            }
            } else {
            this.fileListZip.push(data);
            }
    
            this.fileListZip.forEach(item => {
				if (item.index === data.index) {
					this.$set(item, "percent", data.percent);
				}
				const status = item.percent < 100;
				this.$set(item, "uploadStatus", status);
            });
            console.log('this.fileListZip',this.fileListZip);
            const isAllFinished = this.fileListZip.every(item => item.percent === 100);
            if (isAllFinished) {
				this.afterUpload(3);
				this.uploadFileFlag = false;
				this.uploadFileStatus = false;
			} else {
				this.uploadFileStatus = true;
				this.uploadFileFlag = true;
            }
		},
		afterUpload(){
			this.fileListFinished = this.fileListFinished.concat(this.fileListZip);
			this.fileListZip = [];
		},
		viewDemo(){
			ImagePreview({
				images:['https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/knowDemo.png']
			});
		},
		handleEnterTypeChange(enterType) {
			if (enterType === 1) {
				//分销渠道方
				// 不需要添加工种
				this.formData.workType = ''
				this.workerName = ''
			}
		},
		getRegionList() {
			this.$api.usercenter.getTree({ type: 1 }).then((res) => {
				if (res.success && res.data) {
					let filterArr = res.data.filter((item) => {
						return item.code.length === 2 && item.children
					})
					let uniqueArr = Array.from(new Set(filterArr))
					// 切换成vant省市组件格式
					uniqueArr.map((item) => {
						this.cityList.province_list[item.code] = item.name
						item.children.map((province) => {
							this.cityList.city_list[province.code] = province.name
							;(province.children || []).map((county) => {
								this.cityList.county_list[county.code] = county.name
							})
						})
					})
				}
			})
		},
		onClickLeft() {
			if (this.$store.state.pages.showAddTeachState) {
				this.$store.commit('getAddTeachState', false)
				return
			}
			this.$router.go(-1)
		},
		goHome() {
			// 回首页
			this.$router.push('/home')
		},
		download(){
			let myObj = {
				method: "get",
				url: `${URL.API}/saas/v1/branchApplyEnclosure/download`,
			};
			exportMethod(myObj,true);
		},
		onConfirm(values) {
			this.value = values
				.filter((item) => !!item)
				.map((item) => item.name)
				.join('/')
			this.formData.regionCode = values[2].code
			this.showArea = false
		},

		// 获取验证码
		getAuthCode() {
			if (!this.formData.phone) {
				this.$toast.fail('联系方式不能为空')
				return
			}
			if (!this.isPhone.test(this.formData.phone)) {
				this.$toast.fail('请输入正确的手机号')
				return
			}
			if (this.Countdown > 0) return
			this.Countdown = 60
			this.setCountdown()
			let data = { account: this.formData.phone, type: 2 }
			this.$api.usercenter.getAuthCodePublic({ data })
		},
		// 验证码倒计时
		setCountdown() {
			this.CountdownInterval = setInterval(() => {
				this.Countdown--
				if (this.Countdown <= 0) {
					clearInterval(this.CountdownInterval)
				}
			}, 1000)
		},
		getAuthCodeEmail() {
			if (!this.formData.email) {
				this.$toast.fail("邮箱不能为空");
				return;
			}
			if (!this.isEmail.test(this.formData.email)) {
				this.$toast.fail("请输入正确的邮箱账号");
				return;
			}
			if (this.CountdownEmail > 0) return;
			this.CountdownEmail = 60;
			this.setCountdownEmail();
			let data = { account: this.formData.email, type: 2 };
			this.$api.usercenter.getAuthCodePublic({ data });
		},
		// 验证码倒计时
		setCountdownEmail() {
			this.CountdownIntervalEmail = setInterval(() => {
				this.CountdownEmail--;
				if (this.CountdownEmail <= 0) {
				clearInterval(this.CountdownIntervalEmail);
				}
			}, 1000);
		},
		// 查看机构入驻协议
		checkInstitutions(type) {
			//   if (type == 1) {
			//     this.Protocol = InstitutionInProtocol;
			//     this.protocolTitle = "《线下实训资源提供机构承诺书》";
			//   } else {
			//     this.Protocol = OfflineTrainingProtocol;
			//     this.protocolTitle = "《机构入驻合作协议》";
			//   }
			//   this.isShowInstitutionInProtocol = true;
			if (type == 1) {
				this.show1 = true
			} else {
				this.show2 = true
			}
		},
		// 查看下线下实训协议
		checkTraining() {
			//   this.isShowOfflineTrainingProtocol = true;
			this.show2 = true
		},
		// 点击申请
		apply() {
			let { regionCode, isJoinTalent } = this.formData
			this.formData.isJoinTalent = Number(isJoinTalent)
			if (this.formData.enterType === 7) {
				this.formData.isJoinTalent = 0
			}
			let { success, title } = checkForm(this.formData)
			if (!this.checked) {
				this.$toast.fail('请勾选协议！')
				return
			}
			if (!this.isPhone.test(this.formData.phone)) {
				this.$toast.fail('请输入正确的手机号')
				return
			}
			if (!this.reg.test(this.formData.organCode)) {
				this.$toast.fail('统一社会信用代码18位数字或大写字母组成')
				return
			}
			if (!success) {
				this.$toast.fail(`${title}不能为空`)
				return
			}

			this.$api.rensheUsercenter.saveJoinTrain(this.formData).then((res) => {
				if (res.success) {
					this.$toast('提交成功，请等待审核，审核结果将以短信发送，请注意查收')
					this.$router.go(-1)
				}
			})
		},
		// 上传图片
		readFile(e, type) {
			// type 1 单张  2 五张
			let { businessLicenseImage, trainTeacherImage } = this.formData
			let file = e.target.files[0]
			var formData = new FormData()
			formData.append('file', file)
			if (file.size / 1024 / 1024 > 5) {
				this.$notify({ type: 'warning', message: '上传图片大小不超过5M' })
				return
			}
			this.$api.rensheUsercenter.uploadPicture(formData).then((res) => {
				if (type == 1) {
					this.formData.businessLicenseImage = res.data
				} else {
					if (trainTeacherImage.length < 10) {
						trainTeacherImage.push(res.data)
						return
					}
					this.$toast.fail('最多上传10张照片')
				}
			})
		},
		readFileImg(e, type,imgType) {
			// type 1 单张  2 五张 
			let { letterList,materialList,deviceList,deviceRarList,materialRarList } = this.formData
			let file = e.target.files[0]
			var formData = new FormData()
			formData.append('file', file)
			if (file.size / 1024 / 1024 > 5) {
				this.$notify({ type: 'warning', message: '上传图片大小不超过5M' })
				return
			}
			this.$api.rensheUsercenter.uploadPicture(formData).then((res) => {
				if (imgType == 'letterList') {
					this.formData.letterList = [{
						url:res.data,
						name:'',
					}]
				} else if(imgType=='deviceList'){
					if ((deviceList.length+deviceRarList.length) < 10) {
						deviceList.push({
							url:res.data,
							name:''
						})
						return
					}
					this.$toast.fail('最多上传10个文件')
				}else{
					if ((materialRarList.length + materialList.length) < 10) {
						materialList.push({
							url:res.data,
							name:''
						})
						return
					}
					this.$toast.fail('最多上传10个文件')
				}
			})
		},
		// 获取工种
		workerCode(val) {
			this.formData.workType = val
		},
		// 删除图片
		delLicenseImg(e) {
			this.formData.businessLicenseImage = ''
		},
		delTeachImg(index) {
			this.formData.trainTeacherImage.splice(index, 1)
		},
		delTeachZip(index) {
			this.fileListFinished.splice(index,1);
			this.formData.urlList.splice(index, 1)
		},
		delLetterImg(index) {
			this.formData.letterList.splice(index, 1)
		},
		delDeviceImg(index) {
			this.formData.deviceList.splice(index, 1)
		},
		delZipMix(type,index) {
			if(type=='deviceRarList'){
				this.previewList.deviceRarList.fileListFinished.splice(index,1);
				this.formData.deviceRarList.splice(index, 1)

			}else if(type=='materialRarList'){
				this.previewList.materialRarList.fileListFinished.splice(index,1);
				this.formData.materialRarList.splice(index, 1)
			}
		},
		delMaterImg(index) {
			this.formData.materialList.splice(index, 1)
		},
		// 添加班主任信息
		addTeacherInfo() {
			if (this.formData.headTeacherVoList.length > 20) {
				this.$$toast.fail('至多可添加20条班主任信息')
				return
			}
			this.$store.commit('getTeachItemInfo', {
				id: this.activeId++,
				certificateImgs: [],
			})
			this.$store.commit('getAddTeachState', true)
		},
		// 获取添加的班主任信息
		getTeachInfo(val) {
			this.formData.headTeacherVoList.push(val)
		},
		// 删除老师信息
		delTeachInfo(index) {
			this.formData.headTeacherVoList.splice(index, 1)
		},
		// 编辑老师信息
		editTeachInfo(item) {
			this.$store.commit('getTeachItemInfo', item)
			this.$store.commit('getAddTeachState', true)
		},
		getWorkerName(val) {
			this.workerName = val
		},
		// 关闭协议弹窗
		hidePopup() {
			this.isShowInstitutionInProtocol = false
		},
	},
}
</script>

<style lang="less" scoped>
@import '../assets/css/trainInstitutionsIn.less';
    .auth-code-mix{
        width: 230px;
        height: 58px;
        position: relative;
        border-radius: 50px;
		opacity: 0.7;
		overflow:hidden;
        border: 1px solid #3860FF;
        text-align: center;
        font: 400 24px PingFangSC-Regular, PingFang SC;
        line-height: 58px;
        color: #3860FF;
    }
/deep/.van-field__label {
	padding-left: 5px;
	box-sizing: border-box;
}
/deep/.van-cell--required::before {
	left: 15px;
}
.license-box-letter{
	/deep/.van-cell::after{
		border:none;
	}
}
.demoLetter::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16PX;
    bottom: 0;
    left: 16PX;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}
.demoLetter{
  position: relative;
  background: #fff;
  padding:24px 30px 24px;
  font-size: 30px;
  .right-box{
    display: flex;
    align-items: center;
  }
	img {
    width:156px;
    margin-top:24px;
  }
  span{
    color:#3860FF;
    margin-left: 24px;

  }
}
.mix-zip{
	border-bottom: 1px solid #ebedf0;
	.teacher-info-box{
		padding-top:0;
	}
	.photo_box{
		border-bottom:none;
	}
	.van-cell{
		&:nth-child(2){
			padding-top:0;
		}
		&::after{
			border:none;
		}
	}
	// .mix-btn-upload{
	// 	padding-top:0;
	// 	padding-bottom:0;
	// }
}
</style>
